import React, {useContext, useEffect, useState} from 'react';
import FormContainer from "../formBuilding/FormContainer";
import {documentStore} from "../documentStore";
import {getDocument} from "../KiteDocuments/api";
import {useParams} from "react-router-dom";
import {Form} from "formBuilding";
import {createOrUpdateCustomer, postFromSubmission, submission} from "../Services/api";
import * as yup from "yup";
import FormFooter from "../Molecules/FormFooter";
import FormSubmitted from "../Molecules/FormSubmitted";
import {CATEGORY_TAGS} from "../helpers/constants";
import PageHeader from "../Molecules/PageHeader";
import {get, kebabCase} from "lodash";
import {checkVisited, getPageVisitInfo} from "../Services/VisitPage";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const firstName = urlParams.get('f');
const lastName = urlParams.get('l');
const group = urlParams.get('gr');
let groupCode = null;

const highlighElementWithText = (text) => {
  // remove any element with highlight-conditional class
  const highlightedConditionalElements = window.document.querySelectorAll('.highlight-conditional');
  highlightedConditionalElements.forEach((highlightedElement) => {
    highlightedElement.remove();
  });

  const element = window.document.evaluate(`//*[contains(text(), "${text}")]`, window.document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
  if (element) {
    const y = (element.getBoundingClientRect().top + window.scrollY) - 16;

    // remove highlight class from any other elements
    const highlightedElements = window.document.querySelectorAll('.highlight');
    highlightedElements.forEach((highlightedElement) => {
      highlightedElement.classList.remove('highlight');
    });

    // add highlight effect class to parent element
    element.parentElement.classList.add('highlight');

    // scroll to the element only if it's not already in view

    if (element.getBoundingClientRect().top < 0 || element.getBoundingClientRect().top + 300 > window.innerHeight) {
      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    }
    return element;
  }
  return false;
}



/**
 * Container Component for the from edit and the actual form
 * @returns {JSX.Element}
 * @constructor
 */
const Kitecamp = ({type='live'}) => {
  let { id } = useParams();
  const {docState, docDispatch} = useContext(documentStore);
  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(false);
  const [document, setDocument] = useState({});

  useEffect(() => {
    window.onmessage = function(e) {
      if (type !== 'live') {
        const data = e.data;
        if (data.type === "highlight") {
          const item = data?.item;
          if (item) {
            const text = item.label || item.paragraph1 || item.paragraph2 || item.paragraph3 || item.paragraph4 || item.textValue;
            if (text) {
              const success = highlighElementWithText(text);
              if (!success) {
                // if its conditional highlight log it
                if (item.conditional && item.conditional.when !== 'always') {
                  // highlight conditional.questionLabel
                  const conditionalText = item.conditional.questionLabel;
                  if (conditionalText) {
                    const parentElement = highlighElementWithText(conditionalText);
                    // inject a message below the element to say the element is visible when the condition is met
                    if (parentElement) {
                      const messageElement = window.document.createElement('div');
                      messageElement.innerHTML = `The ${item.label ? 'question': ''} <b>"${item.label || 'element'}"</b> is visible when the question <b>"${conditionalText}"</b> above contains <b>"${item.conditional.contains}"</b>`;
                      messageElement.classList.add('highlight-conditional');
                      parentElement.parentElement.appendChild(messageElement);
                    }
                  }
                }
              }
            }
          }
        } else if (data.type === "update") {
          const updateDocument = data?.document;
          if (updateDocument) {
            docDispatch({ type: 'update document', payload: updateDocument });
          }
        }
      }
    }
    return () => {
      window.onmessage = null;
    }
  }, [type, docDispatch]);

  useEffect(() => {
    if (id) {
      getDocument(id).then(res => {
        let doc = res.data[0];
        if (doc.document) {
          setDocument(doc);
          if (doc.document) {
            // Hack in some email validation and remove first name and last name and email if they are in the url
            doc.liveDocument.shape.forEach(formElement => {
              if (formElement.id === 'email') {
                formElement.validation = yup.string().email().required('An email is required');
              } else if (formElement.required) {
                if (formElement.type === 'multiSelect') {
                  formElement.validation = yup.array().test("optionSelected", "Please select an option",
                    value => value.filter(option => option.selected).length > 0);
                } else if (formElement.type === 'textField') {
                  formElement.validation = yup.string().required('This field is required');
                }
              }
            });

            // Remove firstName and lastName from shape array if it's in the url
            if (firstName && lastName) {
              doc.liveDocument.shape = doc.liveDocument.shape.filter(formElement => {
                return formElement.id !== 'firstName' && formElement.id !== 'lastName';
              });
            }

            // remove share link if this is a group entry
            if (group) {
              doc.liveDocument.shape = doc.liveDocument.shape.filter(formElement => {
                return formElement.id !== 'share_link' && formElement.id !== 'share_group_question';

              });
            } else { // generate a random 10 character string to identify the group
              const randomString = Math.random().toString(36).substring(2, 15);
              groupCode = randomString;
              doc.liveDocument.group = randomString;
            }


            const title = get(doc, 'name', 'No kite name found');
            const eventId = doc.eventId || doc.campaignId;
            checkVisited(title, eventId, firstName, lastName);

            window.document.title = get(doc, 'liveDocument.pageHeader[0].eventTitle', 'Rose Hospitality');

            docDispatch({ type: 'update document', payload: type === 'live' ? doc.liveDocument : doc.document });
          }
          setLoading(false);
        }
      });
    }
  }, [id, docDispatch, type]);

  /**
   * Structure our values in to tags and deal with multi answer questions.
   * @param tagName
   * @param tags
   * @param label
   * @param values
   * @param key
   */
  const processTags = (tagName, tags, label, values, key) => {
    if (tags[tagName]) {
      const keyValue = getLabelAndValue(label, values[key], true);
      if (keyValue) {
        tags[tagName].push(keyValue);
      }
    }
  }

  /**
   * Get the label and value for the tags
   * @param label
   * @param value
   * @returns {{label, value: *}}
   */
  const getLabelAndValue = (label, value, join = false) => {
    if (Array.isArray(value)) {
      let filteredValue = value
        .filter((v) => v.selected)
        .map((v) => `${v.title}${v.otherValue ? `, ${v.otherValue}` : ''}`);
      if (join) {
        filteredValue = filteredValue.join(';');
      }
      if (filteredValue !== "" && filteredValue.length > 0) {
        return {label: label, value: filteredValue};
      } else {
        return false;
      }
    } else {
      if (value && value !== "") {
        return {label: label, value: value};
      } else {
        return false;
      }
    }
  }

  /**
   * POST to the new API
   * @param values
   */
  const newAPIProcess = (state, values) => {
    const properties = {};
    const baseProperties = ['firstName', 'lastName', 'email'];
    Object.keys(values).forEach(key => {
      const matchingField = (state.shape.filter((field) => field.id === key));
      const label = matchingField[0].shortLabel || matchingField[0].propertyName || matchingField[0].label;
      if (label && matchingField[0].category && values[key] && !baseProperties.includes(key)) {
        const keyValue = getLabelAndValue(label, values[key]);
        if (keyValue) {
          properties[`${matchingField[0].category.toLowerCase()}--${kebabCase(key)}`] = keyValue;
        }
      }
    });

    const submissionData = {
      email: values.email,
      firstName: firstName || values.firstName,
      lastName: lastName || values.lastName,
      eventId: document.eventId,
      campaignId: document.campaignId,
      formId: document._id,
      venueId: document.siteId,
      properties: properties
    }

    if (groupCode) {
      submissionData.groupCode = groupCode;
    }

    if (group) {
      submissionData.groupCode = group;
    }

    if (properties['misc--opt-in']) {
      submissionData.optIn = properties['misc--opt-in'].value[0] === 'OK';
    }

    submission(submissionData);
    return properties;
  }

  return (
    <React.Fragment>
      {!loading &&<FormContainer>
        <PageHeader {...docState.present.pageHeader[0]} />
        {!done && <Form
          shape={docState.present.shape}
          onSubmit={({values, setSubmitting, setErrors}) => {
          if (values.email && type === 'live') {
            // Pass to the new API process.
            const newProperties = newAPIProcess(docState.present, values);

            // Old API process
            let tags = CATEGORY_TAGS;

            let customer = {
              email: values.email,
              firstName: firstName || values.firstName,
              lastName: lastName || values.lastName,
              siteId: document.siteId,
              groups: [document.name],
              typeOfGuest: document.targetCustomer
            };

            if (document.eventId) {
              customer.events = [document.eventId];
            }

            if (document.campaignId) {
              customer.campaigns = [document.campaignId];
            }

            Object.keys(values).forEach(key => {
              const matchingField = (docState.present.shape.filter((field) => field.id === key));
              const label = matchingField[0].shortLabel || matchingField[0].propertyName || matchingField[0].label;
              if (label && matchingField[0].category) {
                processTags(matchingField[0].category.toLowerCase()+'Tags', tags, label, values, key);
              }
            });

            createOrUpdateCustomer({...customer, ...tags});

            const pageVisitInfo = getPageVisitInfo();

            // Make a record of all form submissions
            postFromSubmission({
              siteId: document.siteId,
              eventId: document.eventId,
              kiteTitle: get(document, 'name', 'No Title'),
              campaignId: document.campaignId,
              firstPageLoad: pageVisitInfo?.createdAt,
              formId: document._id,
              typeOfGuest: document.targetCustomer,
              email: values.email,
              groupCode: groupCode || group,
              firstName: firstName || values.firstName,
              lastName: lastName || values.lastName,
              properties: newProperties,
              ...tags
            });
          }
          setSubmitting(true);
          setDone(true);
        }}/>}
        {done && <FormSubmitted {...docState.present.submitted[0]} />}
        <FormFooter />
      </FormContainer>}
    </React.Fragment>
  );
};

Kitecamp.propTypes = {

};

export default Kitecamp;
